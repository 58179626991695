import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import Introduction004 from './Introduction004'
import Introduction005 from './Introduction005'
import Introduction006 from './Introduction006'
import Introduction007 from './Introduction007'
import Introduction008 from './Introduction008'
import Introduction009 from './Introduction009'
import Introduction010 from './Introduction010'
import Introduction011 from './Introduction011'
import Introduction012 from './Introduction012'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;
  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }
  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;
    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }
    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }
    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: auto;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }
    p {
      padding-bottom: 30px;
      line-height: 2;
      position: relative;
      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }
      .p-list-link {
        position: absolute;
        top: 6px;
        right: 2px;
        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
      }
      &.txt-color-red {
        color: #ff6969;
      }
    }
    .link-div {
      width: 100%;
      display: flex;
      justify-content: left;
      padding-bottom: 30px;
      > div {
        width: 50px;
      }
      .link-p {
        width: calc(100% - 50px);
        .p-list-link {
          position: absolute;
          top: -3px;
          left: 30px;
          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 11px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }
        }
      }
    }
    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }
  }
  ${BreakPoints.large} {
  }
`
type Props = {
  /** 広告の番号 */
  no: number
}

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0047 = (props: Props): JSX.Element => (
  <Wrapper>
    <ColumnH1 label="高度プロフェッショナル制度とは？仕組みや手続きについて解説" />
    {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
    <p className="p-txt-date">2023.03.29</p>
    <section>
      <p>
        日本の雇用環境現場では、多くの場合、働いた時間に対して賃金を支払う方法が採用されており、労働者は予め決められた時間内は会社の指揮命令を受けて、期待される労務を提供することが前提です。そこで、2019年4月1日以降、
        <span>
          必ずしも時間と賃金が紐づかない特定の業種に対して、労働時間、休憩、休日だけでなく、深夜の割増賃金も適用されない高度プロフェッショナル制度が創設
        </span>
        されました。今回は、同制度に注視し、解説していきます。
      </p>
      <img src="/images/column/details/c0047.jpg" alt="キンクラコラム画像47" />
      <h1 id="toc1">高度プロフェッショナル制度の全体像</h1>
      <p>
        後述する⾼度の専門的知識等を有する職業に従事する者が「職務の範囲が明確」であることを前提に、「⼀定の年収要件」を満たす労働者を対象として、「労使委員会の決議」および「労働者本⼈の同意」を得た後、「年間104⽇以上の休⽇確保措置」、「健康・福祉確保措置」等を講ずることにより、労働基準法上の「労働時間、休憩、休⽇、深夜の割増賃⾦」に関する規制が適用されなくなる制度です。
        <br />
        <br />
        なお、<span>「一定の年収要件とは」1,075万円以上であること</span>
        とされています。
      </p>

      <h1 id="toc2">高度プロフェッショナル制度の対象業務</h1>
      <p>
        前提として、具体的な指示を受けて行うものは含まれず、対象業務は以下の業種です。
        <br />
        ・金融工学等の知識を用いて行う金融商品の開発業務
        <br />
        ・資産運用または有価証券の売買等の取引のうち、投資判断に基づく資産運用として行う業務等
        <br />
        ・有価証券市場における相場等の動向または有価証券の価値分析、評価に基づく助言業務等
        <br />
        ・顧客の事業の運営に重要な事項についての調査、分析およびこれに基づく考案または助言業務
        <br />
        ・新技術、商品または役務の研究開発業務
        <br />
        <br />
        上記の業務に限定されています。よって、具体的な指示を与えない場合であってもそもそも対象業務に含まれていない場合は高度プロフェッショナル制度の対象として扱うことはできません。
        <br />
        <br />
        なお、対象業務とは、部署全体ではなく、対象業種に対して労働者に実際に従事させる業務を指します。よって、部署としては対象業種を含む名称であっても、業務の全てが対象業務に該当するものではない場合、実際に従事する業務で判断します。
      </p>

      {/** ここに9パターンの広告設定する */}
      {props.no === 0 && <Introduction004 />}
      {props.no === 1 && <Introduction005 />}
      {props.no === 2 && <Introduction006 />}
      {props.no === 3 && <Introduction007 />}
      {props.no === 4 && <Introduction008 />}
      {props.no === 5 && <Introduction009 />}
      {props.no === 6 && <Introduction010 />}
      {props.no === 7 && <Introduction011 />}
      {props.no === 8 && <Introduction012 />}

      <h1 id="toc3">高度プロフェッショナル制度創設にあたっての手続き</h1>
      <h2 id="toc3-1">1.労使委員会の設置</h2>
      <p>
        構成員は使用者と労働者代表となり、賃金や労働時間に関する内容を審議し、使用者に対して意見を述べる委員会となります。高度プロフェッショナル制度導入にあたっては、
        <span>労使委員会設置後、委員の5分の4以上の多数</span>
        により決議をすることとなります。前提として、労働者代表が半数を占めなければなりませんが、労使各1名の合計2名では労使委員会として認められません。
      </p>
      <h2 id="toc3-2">2.労使委員会での決議</h2>
      <p>
        決議すべき内容として、以下の項目が挙げられます。
        <br />
        ・対象業務（前述）
        <br />
        ・対象労働者の範囲（職務が明確かつ年収要件を満たす者）
        <br />
        ・健康管理時間の把握（タイムカードおよび自己申告）
        <br />
        ・休日の確保（年間104日以上かつ4週4日以上の休日）
        <br />
        ・選択的措置（勤務間インターバルの確保等）
        <br />
        ・健康管理時間の状況に応じた健康、福祉確保措置（医師による面接指導等）
        <br />
        ・同意の撤回に関する手続き（撤回の申し出先となる部署および担当者、撤回の方法等）
        <br />
        ・苦情処理措置（対象労働者からの苦情処理を使用者が実施することおよびその具体的な内容）
        <br />
        ・不利益取扱いの禁止（同意しなかった労働者への不利益取扱いをしない旨の決議）
        <br />
        ・その他厚生労働省令で定める事項（決議の有効期間等）
      </p>
      <h2 id="toc3-3">3.労働基準監督署への届出</h2>
      <p>事業場の所轄労働基準監督署への届出が必要です。</p>
      <h2 id="toc3-4">4.対象労働者の同意の取得</h2>
      <p>
        同意をした場合には労働時間等の労働基準法で定める規定が適用されない旨を説明し、書面で同意を取得することが求められます。
      </p>
      <h2 id="toc3-5">5.実際に対象業務に従事させる</h2>
      <p>
        ここまでプロセスを踏むことで、実際に対象業務に対して高度プロフェッショナル制度の対象者として、従事させることが可能となります。
      </p>

      <h1 id="toc4">高度プロフェッショナル制度創設後の留意点</h1>
      <p>
        年収要件の「1,075万円以上」を満たしている場合であっても、特定の月に偏った多額の賃金を支払い、他の月には定額となる賃金を支払うような場合には、
        <span>最低賃⾦法に抵触する可能性</span>がある点は留意しておくべきです。
        <br />
        <br />
        「具体的な指示」について、各々の労働者が従事する「時間に裁量を失わせるような指示」を発することを指します。そのような意識が無くても、業務量と比べて著しい短納期の設定や実質的に時間的な指示と認められる場合も裁量を失わせるような指示に含むとされていますので、注意が必要です。
        <br />
        <br />
        時間に関して具体的な指示をしないからとって、安全配慮義務や職場環境配慮義務がなくなるということはありません。
        <span>
          高度プロフェッショナル制度導入後も対象労働者の健康管理を適切に実施すること
        </span>
        が求められます。
      </p>

      <h1 id="toc5">最後に</h1>
      <p>
        高度プロフェッショナル制度は変形労働時間制や裁量労働制と比較しても導入までに多くのハードルがあり、直ぐに創設できる制度ではありません。また、労働者から同意を取得した場合であっても撤回が可能であることから、実質的に法律で求める内容を下回っていないかの定期的な精査が極めて重要です。
      </p>
    </section>
  </Wrapper>
)

export default ColumnDetailInner0047
